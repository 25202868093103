import * as React from "react"
import PropTypes from "prop-types"

import config from "../../config/navigation"

import MenuItem from "./menuItem"

const MainMenu = () => {


  return (
    <div className="flex flex-row space-x-4 my-2 py-4 border-gray-800 border-t-2 border-b-2">
      {config.mainMenu
        .sort((a, b) => a.order - b.order)
        .map(({ id, title, link }) => {
          return <MenuItem key={id} title={title} link={link} />
        })}
    </div>
  )
}

export default MainMenu
