import * as React from "react"
import PropTypes from "prop-types"

const Footer = () => {
  return (
    <footer className="flex flex-row justify-between my-2 py-4 border-gray-700 border-t-2">
      &copy; with-lasers {new Date().getFullYear()}
    </footer>
  )
}

export default Footer
