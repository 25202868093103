import * as React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"

const MenuItem = ({ title, link }) => {
  
  if (link.substr(0,4) === "http") {
    return (
      <div>
        <a href={link} target="_blank">{title}</a>
      </div>
    )  
  } else {
    //Assume it's a site link
    return (
      <div>
        <Link to={link}>{title}</Link>
      </div>
    )
  }
}

export default MenuItem
